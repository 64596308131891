import React from "react";
import DefaultLayout from "../layouts/DefaultLayout";
import PageHeader from "../components/PageHeader";
import DefaultHelmet from "../seo/DefaultHelmet";
import FeedbackForm from "../components/FeedbackForm/FeedbackForm";
import beaconWidgetIds from "../settings/beacon-widget-ids";
import useBeaconWidget from "../hooks/useBeaconWidget";

const ThemeSupport = () => {
    useBeaconWidget(beaconWidgetIds.THEME);

    return (
        <DefaultLayout isHeaderWithBlackText={true}>
            <DefaultHelmet title="Theme support" description="Theme support form"/>

            <PageHeader title="Theme support"/>

            <section className="contact-page-form container container--mobile-without-side-paddings">
                <div className="contact-page-form__wrapper">
                    <FeedbackForm/>
                </div>
            </section>
        </DefaultLayout>
    );
};

export default ThemeSupport;
