import React, {useState} from "react";

import classNames from "classnames";

const MB_10 = 10 * 1024 * 1024;

const initialFormData = {
    full_name: "",
    email: "",
    store_url: "",
    subject: "",
    description: "",
    theme_name: "Gain",
    file: ""
};

const FeedbackForm = ({prefixForFieldsId, ariaParent}) => {
    const [formData, setFormData] = useState(initialFormData);

    const [submitMessage, setSubmitMessage] = useState("");
    const [fileErrorMessage, setFileErrorMessage] = useState("");
    const [isFormError, setIsFormError] = useState(false);
    const [isServerError, setIsServerError] = useState(false);
    const [invalidFields, setInvalidFields] = useState({});
    const [buttonText, setButtonText] = useState("Send");
    const [isFetching, setIsFetching] = useState(false);

    function getFormData(object) {
        const formData = new FormData();

        Object.keys(object).forEach(key => formData.append(key, object[key]));

        return formData;
    }

    function resetForm() {
        setFormData(initialFormData);

        setTimeout(() => {
            setButtonText("Send");
            setSubmitMessage("");
        }, 5000);
    }

    const onFormSubmit = async (e) => {
        e.preventDefault();

        setIsFetching(true);
        setSubmitMessage("");
        setButtonText("");
        setFileErrorMessage("");
        setInvalidFields({});

        try {
            const response = await fetch("/wp-json/contact-form-7/v1/contact-forms/3349/feedback", {
                body: getFormData(formData),
                method: "POST"
            });
            const responseJSON = await response.json();

            if (!responseJSON.status) {
                console.log(`Error: ${responseJSON.message}`);
                throw new Error("Server error");
            }

            const hasntInvalidFields = !responseJSON?.invalid_fields?.length;

            setSubmitMessage(responseJSON?.message);
            setIsFormError(responseJSON?.status !== "mail_sent");
            setIsServerError(hasntInvalidFields && responseJSON?.status !== "mail_sent");

            if (responseJSON.status !== "mail_sent") {
                const invalidFields = responseJSON.invalid_fields.reduce((obj, currentValue) => {
                    obj[currentValue.into.replace("span.wpcf7-form-control-wrap.", "")] = currentValue.message;
                    return obj;
                }, {});

                setButtonText("Send");
                setInvalidFields(invalidFields);
                return;
            }

            setButtonText("Thank you");
            setInvalidFields({});
            resetForm();
        } catch (error) {
            setButtonText("Send");
            setIsServerError(true);
            console.log(`Error: ${error}`);
        } finally {
            setIsFetching(false);
        }
    };

    const handleFieldChange = (key, event) => {
        setFormData({
            ...formData,
            [key]: event.target.value
        });
    };

    const handleFileFieldChange = (event) => {
        const file = event.target?.files[0];

        if (!file) return;

        const allowedTypes = ["image/jpeg", "image/jpg", "image/png", "video/mp4", "video/avi", "video/webm", "image/webp", "application/pdf"];

        if (!allowedTypes.includes(file.type)) {
            setFormData({...formData, file: ""});
            setFileErrorMessage("Invalid file type. Please select an image(jpeg, jpg, png)/video(mp4, avi, webm) or PDF file.");
            return;
        }

        if (file.size > MB_10) {
            setFormData({...formData, file: ""});
            setFileErrorMessage('Maximum file size 10 MB');
            return;
        }

        setFormData(formData => ({...formData, file: file}));
        setFileErrorMessage("");
        setSubmitMessage("");
    };

    const handleClearFileClick = () => {
        setFormData({
            ...formData,
            file: ""
        });
        setFileErrorMessage("");
    }

    const fieldsIds = Object.keys(formData).reduce((ids, id) => {
        ids[id] = `${prefixForFieldsId}${id}`;

        return ids;
    }, {});

    return (
        <form className={"form form--black-text"} onSubmit={onFormSubmit}>
            <p
                className="form__description"
                id={`${ariaParent}-contactDescription`}
            >
                Fill out the form and we will contact you shortly
            </p>

            <div className="form__input-group">
                <label
                    className="form__label"
                    htmlFor={fieldsIds.full_name}
                >
                    First and Last name <span className="form__label-required-symbol">*</span>
                </label>

                <input
                    type="text"
                    className={classNames("form__input", {
                        "form__input--not-empty": formData["full_name"] !== ""
                    })}
                    id={fieldsIds.full_name}
                    name={fieldsIds.full_name}
                    aria-required="true"
                    value={formData["full_name"]}
                    onChange={(event) => handleFieldChange("full_name", event)}
                />
            </div>

            {
                invalidFields.full_name && <div className="form__input-error-message">
                    <p>
                        {invalidFields.full_name}
                    </p>
                </div>
            }

            <div className="form__input-group">
                <label
                    htmlFor={fieldsIds.email}
                    className="form__label"
                >
                    Email <span className="form__label-required-symbol">*</span>
                </label>

                <input
                    className={classNames("form__input", {
                        "form__input--not-empty": formData["email"] !== ""
                    })}
                    type="email"
                    id={fieldsIds.email}
                    name={fieldsIds.email}
                    value={formData["email"]}
                    onChange={(event) => handleFieldChange("email", event)}
                    aria-required="true"
                />
            </div>

            {
                invalidFields.email && <div className="form__input-error-message">
                    <p>
                        {invalidFields.email}
                    </p>
                </div>
            }

            <div className="form__input-group">
                <label
                    className="form__label"
                    htmlFor={fieldsIds.store_url}
                >
                    Website "https://storename.myshopify.com"<span className="form__label-required-symbol"> *</span>
                </label>

                <input
                    type="text"
                    className={classNames("form__input", {
                        "form__input--not-empty": formData["store_url"] !== ""
                    })}
                    id={fieldsIds.store_url}
                    name={fieldsIds.store_url}
                    value={formData["store_url"]}
                    onChange={(event) => handleFieldChange("store_url", event)}
                    aria-required="true"
                />
            </div>

            {
                invalidFields.store_url && <div className="form__input-error-message">
                    <p>
                        {invalidFields.store_url}
                    </p>
                </div>
            }

            <div className="form__input-group">
                <label
                    className="form__label"
                    htmlFor={fieldsIds.subject}
                >
                    Subject<span className="form__label-required-symbol"> *</span>
                </label>

                <input
                    type="text"
                    className={classNames("form__input", {
                        "form__input--not-empty": formData["subject"] !== ""
                    })}
                    id={fieldsIds.subject}
                    name={fieldsIds.subject}
                    value={formData["subject"]}
                    aria-required="true"
                    onChange={(event) => handleFieldChange("subject", event)}
                />
            </div>

            {
                invalidFields.subject && <div className="form__input-error-message">
                    <p>
                        {invalidFields.subject}
                    </p>
                </div>
            }

            <div className="form__input-group form__input-group--no-wrap">
                <label
                    className="form__label"
                    htmlFor="file-input"
                    style={{
                        paddingBottom: '20px',
                        display: "flex",
                        justifyContent: "space-between",
                        gap: "10px",
                        cursor: "pointer"
                    }}
                >
                    <span style={{wordBreak: "break-all", hyphens: "auto"}}>
                        Attach File{formData?.file?.name ? `: ${formData?.file?.name}` : null}
                    </span>
                </label>

                {
                    formData?.file?.name ?
                        <span style={{cursor: "pointer", width: "20px"}} onClick={handleClearFileClick}>X</span> : null
                }

                <input
                    type="file"
                    id="file-input"
                    className={classNames("form__input", {
                        "form__input--not-empty": formData["file"] !== ""
                    })}
                    style={{display: 'none'}}
                    onChange={handleFileFieldChange}
                    accept=".jpg, .jpeg, .png, .mp4, .avi, .webm, .webp, .pdf"
                />
            </div>

            <div className="form__input-group">
                <label
                    className="form__label"
                    htmlFor={fieldsIds.description}
                >
                    Your question<span className="form__label-required-symbol"> *</span>
                </label>

                <textarea
                    className={classNames("form__input", {
                        "form__input--not-empty": formData["description"] !== ""
                    })}
                    style={{resize: "none"}}
                    id={fieldsIds.description}
                    name={fieldsIds.description}
                    value={formData["description"]}
                    onChange={(event) => handleFieldChange("description", event)}
                    aria-required="true"
                />
            </div>

            {
                invalidFields.description && <div className="form__input-error-message">
                    <p>
                        {invalidFields.description}
                    </p>
                </div>
            }

            {
                submitMessage && <div className={classNames("form__message", {"form__message--error": isFormError})}
                >
                    <p>
                        {submitMessage}
                    </p>
                </div>
            }

            {
                fileErrorMessage && <div className="error-file">
                    <p>
                        {fileErrorMessage}
                    </p>
                </div>
            }

            {
                isServerError && <div style={{marginTop: "20px"}}>
                    <p style={{color: "red"}}>
                        Sorry, an unexpected error occurred on the server, you can contact us directly on <a
                        style={{color: "red"}} href="mailto:support@utdweb.team">support@utdweb.team</a>
                    </p>
                </div>
            }

            <button
                className="button button--transparent button--large form__submit"
                type="submit"
                disabled={isFetching}
            >
                {buttonText}

                {
                    isFetching && <span className="btn-loader"/>
                }
            </button>
        </form>
    );
};

export default FeedbackForm;
